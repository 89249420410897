<template>
  <vue-final-modal
    v-if="!isMobile"
    v-model="isOpen"
    class="modal-demo-game"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: TRANSITION_DURATION }"
    :contentTransition="{ mode: 'in-out', duration: TRANSITION_DURATION }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="isOpen = false" />
        <span class="title">{{ content?.label }}</span>
      </div>
      <p class="text">{{ content?.description }}</p>
      <div class="actions">
        <button-base type="gray" size="md" @click.once="confirm">
          {{ content?.[modalType] }}
        </button-base>

        <button-base type="primary" size="md" @click="isOpen = false">
          {{ content?.demo }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>

  <bottom-sheet-games-demo v-else :content="content" :confirm="confirm" :modalType="modalType" :isDemo="isDemo" />
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import { storeToRefs } from 'pinia';

  import type { IGamePage } from '~/types';

  import { ModalName } from '@skeleton/consts/modals';

  const props = defineProps<{
    content?: IGamePage['demoModal'];
    isDemo: boolean;
  }>();

  const emit = defineEmits(['playReal']);

  const { openModalSync } = useModalStore();

  const TIMER_DURATION = 60000;
  const TRANSITION_DURATION = 200;

  const layoutStore = useLayoutStore();
  const { isLoggedIn } = storeToRefs(useProfileStore());
  const { activeAccount } = storeToRefs(useWalletStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const isOpen = ref(false);

  const modalType = computed<'real' | 'deposit' | 'registration'>(() => {
    if (!isLoggedIn.value) return 'registration';
    if (activeAccount.value?.balance ?? 0 > 0) return 'real';
    return 'deposit';
  });

  const { openWalletModal } = useTransactionStore();
  let timer: ReturnType<typeof setTimeout>;
  const confirm = async (): Promise<void> => {
    isOpen.value = false;
    clearTimeout(timer);
    if (modalType.value === 'real') emit('playReal');
    else if (modalType.value === 'deposit') {
      await openWalletModal('deposit');
    } else if (modalType.value === 'registration') openModalSync(ModalName.SIGN_UP);
  };

  const startTimer = (): void => {
    timer = setTimeout(() => {
      if (!layoutStore.modals.wallet) isOpen.value = true;
    }, TIMER_DURATION);
  };

  watch(
    () => props.isDemo,
    (newValue: boolean) => {
      if (!newValue) clearTimeout(timer);
    }
  );

  onBeforeMount(() => {
    if (props.isDemo) {
      const { mobileGameModalInfo } = useGamesStore();
      if (!isLoggedIn.value && !mobileGameModalInfo) isOpen.value = true;
      startTimer();
    }
  });

  onBeforeUnmount(() => {
    clearTimeout(timer);
  });
</script>

<style src="~/assets/styles/components/modal/demo-game.scss" lang="scss" />
