<template>
  <div class="games-demo">
    <client-only>
      <layout-bottom-sheet ref="bottomSheet" height="31" spaceBetween>
        <template #header>
          <span class="games-demo-title">{{ content?.label }}</span>
          <span class="games-demo-description">{{ content?.description }}</span>
        </template>

        <div class="actions">
          <button-base type="ghost" class="demo-btn" @click="toggleClose">
            <span>{{ content?.demo }} </span>
          </button-base>

          <button-base
            class="real-play-btn"
            type="primary"
            size="sm"
            @click.once="playReal"
          >
            <span> {{ content?.[modalType as keyof typeof content] }}</span>
          </button-base>
        </div>
      </layout-bottom-sheet>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  import type { IBottomSheetComponent, IGamePage } from '~/types';

  const bottomSheet = ref<Maybe<IBottomSheetComponent>>();
  const emit = defineEmits(['cancelPayment']);
  let intervalId: ReturnType<typeof setTimeout>;

  const props = defineProps<{
    content?: IGamePage['demoModal'];
    confirm: () => void;
    isDemo: boolean;
    modalType: string;
  }>();

  const toggleOpen = (): void => {
    bottomSheet.value?.open();
  };
  const toggleClose = (): void => {
    bottomSheet.value!.close();
  };
  const playReal = (): void => {
    props.confirm();
    toggleClose();
  };

  onMounted(async () => {
    await nextTick();
    if (props.isDemo) {
      intervalId = setInterval(() => {
        toggleOpen();
      }, 60000);
    }
  });

  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
</script>

<style src="~/assets/styles/components/bottom-sheet/games-demo.scss" lang="scss" />
